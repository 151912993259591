import Network from '@/services/network'
import { ref } from 'vue'

export default function useReport () {
    const network = new Network
    const receivable = ref([])
    const cashBank = ref([])
    const projectBudgetBalance = ref([])
    const projectCashBankBalance = ref([])
    const fd6BudgetBalance = ref([])
    const expenseCategorySummary = ref([])
    const expenseProjectSummary = ref([])

    const fetchReceivable = (query) => {
       return network.api('get', '/dashboard/accounting/receivable'+query)
    }
    const fetchPayable = (query) => {
        return network.api('get', '/dashboard/accounting/payable'+query);
    }
    const fetchCashBank = (query) => {
        network.api('get', '/dashboard/accounting/cash-and-bank'+query)
        .then(res => {
            cashBank.value = res.data.data
        })
    }
    const fetchProjectBudgetBalance = (query) => {
        network.api('get', `/dashboard/accounting/project-budget-balance${query}`)
        .then(res => {
            projectBudgetBalance.value = res.data
        })
    }
    const fetchProjectWiseCashBankBalance = (query) => {
        network.api('get', '/dashboard/accounting/project-cash-bank-balance-summary'+query)
            .then(res => {
                projectCashBankBalance.value = res.data
            })
    }
    const fetchFd6BudgetBalance = (query) => {
        network.api('get', '/dashboard/accounting/fd6-budget-balance-summary'+query)
            .then(res => {
                fd6BudgetBalance.value = res.data
            })
    }
    const fetchExpenseCategorySummary = (query) => {
        network.api('get', '/dashboard/accounting/expense-category-summary'+query)
            .then(res => {
                expenseCategorySummary.value = res.data
            })
    }
    const fetchExpenseProjectSummary = (query) => {
        network.api('get', '/dashboard/accounting/expense-project-summary'+query)
            .then(res => {
                expenseProjectSummary.value = res.data
            })
    }
    const fetchDoctorIncentiveReport = (query) => {
        return network.api('get', '/hospital/report/doctor-incentive'+query)
    }

    return {
        receivable,
        cashBank,
        projectBudgetBalance,
        projectCashBankBalance,
        fd6BudgetBalance,
        expenseCategorySummary,
        expenseProjectSummary,
        fetchReceivable,
        fetchPayable,
        fetchCashBank,
        fetchProjectBudgetBalance,
        fetchProjectWiseCashBankBalance,
        fetchFd6BudgetBalance,
        fetchExpenseCategorySummary,
        fetchExpenseProjectSummary,
        fetchDoctorIncentiveReport
    }
}
