<template>
    <div class="table-responsive table-striped my-1" style="min-height: 200px">
        <table class="table">
            <thead class="table-light">
                <tr>
                    <th>SL No</th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th v-for="(column, index) in dynamicColumns" :key="index" class="text-right max-w-100">
                      {{column}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ index+1 }}
                    </td>
                    <td>{{item.serial_no}}</td>
                    <td>{{item.full_name}}</td>
                    <td>{{item.phone}}</td>
                    <td v-for="(column, index) in dynamicColumns" :key="index" class="text-right">
                      {{ commaFormat(item[index]) }}
                    </td>

                </tr>
                <tr class="bg-blue-light">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><strong>TOTAL</strong></td>
                  <td v-for="(column, index) in dynamicColumns" :key="index" class="text-end">
                    {{ commaFormat(getTotal(index)) }}
                  </td>
                </tr>

            </tbody>
        </table>
        <p v-if="!tableData.length" class="mt-5 text-center">No data</p>
    </div>
</template>

<script setup>

import {commaFormat} from "@/services/utils/global";

const props = defineProps({
  tableData: {
    type: Array
  },
  dynamicColumns: {
    type: Object
  }
})

const getTotal = (key) => {
  return props.tableData.reduce((sum, item) => sum + Number(item[key]), 0);
}

</script>

<style scoped>
  .max-w-100 {
    max-width: 100px;
  }
</style>