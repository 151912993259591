<template>
  <div class="card">
    <div class="bg-blue-light p-2">
      <TitleButton
        :showBtn="false"
        title="Doctor Incentive Report"
      />
      <div class="row match-height">
        <div class="col-12 col-lg-12 col-xl-12 col-xxl-12 mt-2">
          <DateQuerySetter
            @onClickGo="onPageChange(1)"
          >
            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
              <label for="colFormLabel" class="col-form-label">Roles</label>
              <v-select
                placeholder="Select Roles"
                v-model="companyRoleId"
                :options="companyRoles"
                label="name"
                :reduce="name => name.id"
              />
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
              <label class="form-label" for="party">Party</label>
              <AsyncSelect
                placeholder="Party Name, ID, Mobile No"
                v-model="contactProfile"
                :api-service="fetchContactProfiles"
                :format-label="formatPatientLabel"
              />
            </div>
          </DateQuerySetter>
        </div>
      </div>
    </div>

    <div class="mt-2 px-2">
      <IncentiveTable
        :tableData="reportData"
        :dynamicColumns="dynamicColumns"
      />

      <Pagination
        :offset="offset"
        @onPageChange="onPageChange"
        ref="pagination"
      />
    </div>

    <Loader v-if="loading"/>

  </div>
</template>

<script setup>
import {inject, onMounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router'
import Loader from "@/components/atom/LoaderComponent.vue";
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import handleCompany from "@/services/modules/company";
import handleRole from "@/services/modules/role";
import useReport from '@/services/modules/dashboard/report.js'
import {useStore} from 'vuex';
import handleContact from '@/services/modules/contact'
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import IncentiveTable from "@/components/molecule/company/report/IncentiveTable.vue";
import Pagination from "@/components/atom/Pagination.vue";

const {fetchDoctorIncentiveReport} = useReport()
const {fetchCompanyDefaultRoles} = handleRole();
const {fetchContactProfiles} = handleContact();
const {formatPatientLabel} = useAsyncDropdownHelper();
const {fetchCompanyInfo} = handleCompany();

const route = useRoute()
const store = useStore();
const router = useRouter();

let offset = ref(20)
const loading = ref(false);
const showError = inject('showError');
const reportData = ref([]);
const dynamicColumns = ref({})
const companyRoles = ref([])
const companyRoleId = ref(null)
const contactProfile = ref(null);
const companyInfo = ref(null);
const isFetchAll = ref(false);

const getIncentiveReport = () => {
  loading.value = true;

  fetchDoctorIncentiveReport(getQuery())
    .then(res => {
      if (!res.status) {
        reportData.value = [];
        dynamicColumns.value = {}
        setPagination({})
        return;
      }

      reportData.value = res.data.data;
      dynamicColumns.value = res.data.dynamic_columns
      setPagination(res.data.pagination)

    }).catch(err => {
      if (err.response) {
        showError(err.response.message)
      }
    }).finally(() => {
      loading.value = false;
    });
}

const getDefaultRoles = async () => {
  const companyQuery = `?company_id=${route.params.companyId}`
  const res = await fetchCompanyDefaultRoles(companyQuery)
  if (res.status) {
    companyRoles.value = res.data;
  }
}

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};

const onPageChange = async (page) => {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  await router.push({path: route.path, query: routeQuery})
  getIncentiveReport()
}

const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

const getQuery = () => {
  let queryObj = {
    company_id: route.params.companyId,
    offset: offset.value
  };

  if (companyRoleId.value) {
    queryObj.company_role_id = companyRoleId.value;
  }

  if (contactProfile.value) {
    queryObj.contact_profile_id = contactProfile.value.id;
  }

  if (isFetchAll.value) {
    queryObj.is_fetch_all = isFetchAll.value;
  }

  if (route.query.page) {
    queryObj.page = route.query.page
  }

  if (route.query.start && route.query.end) {
    Object.assign(queryObj, {
      start_date: route.query.start,
      end_date: route.query.end
    });
  }

  return "?" + new URLSearchParams(queryObj);
}

const getCompany = async () => {
  await fetchCompanyInfo(route.params.companyId).then((res) => {
    companyInfo.value = res.data;
  })
}

onMounted(() => {
  getDefaultRoles();
  getCompany();
})
</script>
